import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import RepertoireListRow from './RepertoireListRow';

const RepertoireList = function ({ data }) {
  return (
    <List
      size='small'
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          className='calendar-list__item'
        >
          <RepertoireListRow data={item} />
        </List.Item>
      )}
    />
  );
};

RepertoireList.propTypes = {
  data: PropTypes.array.isRequired,
};

export default RepertoireList;
