import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import slice from 'lodash/slice';
import PageLayout from 'src/components/Layout';
import useContentful from 'src/helpers/hooks/useContentful';
import {
  Row, Col, Grid, Space, Typography,
} from 'antd';
import RepertoireList from '../components/repertoire/RepertoireList';
import PageTitle from '../components/PageTitle';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export const query = `
{
  repertoire(id: "4KkzECOWXZPbbpFoXAPZmY") {
    pageHeader {
      pageTitle
      pagePhotoCollection {
        items {
          fileName
          url
          width
          height
        }
      }
    }
    opera
    oratorium
    selectedSongs
    operaTitle
    oratoriumTitle
    selectedSongsTitle
  }
}
`;

const RepertoireIndex = function ({ location }) {
  const { lg } = useBreakpoint();
  const { data, loading } = useContentful(query);
  const title = get(data, 'repertoire.pageHeader.pageTitle');
  const mainPhoto = get(data, 'repertoire.pageHeader.pagePhotoCollection.items');

  const operaItems = get(data, 'repertoire.opera.opera');
  const operaTitle = get(data, 'repertoire.operaTitle');
  const oratoriumItems = get(data, 'repertoire.oratorium.oratorium') || [];
  const oratoriumTitle = get(data, 'repertoire.oratoriumTitle');
  const selectedSongsItems = get(data, 'repertoire.selectedSongs.selectedSongs') || [];
  const selectedSongsTitle = get(data, 'repertoire.selectedSongsTitle');

  return (
    <PageLayout
      location={location}
      title={title}
      photo={mainPhoto}
      loading={loading}
    >
      {lg && (
        <PageTitle
          title={title}
          style={{
            textAlign: 'center',
          }}
        />
      )}
      <Space
        direction='vertical'
        size={50}
        style={{ width: '100%' }}
      >
        <div>
          <Title level={4}>{operaTitle}</Title>
          <RepertoireList data={operaItems} />
          <Text><small>(*) in  fragments</small></Text>
        </div>

        <div>
          <Title level={4}>{oratoriumTitle}</Title>
          <Row>
            <Col
              xs={24}
              lg={12}
            >
              <RepertoireList data={slice(oratoriumItems, 0, (oratoriumItems.length / 2))} />
            </Col>
            <Col
              xs={24}
              lg={12}
            >
              <RepertoireList data={slice(oratoriumItems, (oratoriumItems.length / 2))} />
            </Col>
          </Row>
        </div>

        <div>
          <Title level={4}>{selectedSongsTitle}</Title>
          <Row>
            <Col
              xs={24}
              lg={12}
            >
              <RepertoireList
                data={slice(selectedSongsItems, 0, (selectedSongsItems.length / 2))}
              />
            </Col>
            <Col
              xs={24}
              lg={12}
            >
              <RepertoireList data={slice(selectedSongsItems, (selectedSongsItems.length / 2))} />
            </Col>
          </Row>
        </div>
      </Space>
    </PageLayout>
  );
};

RepertoireIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RepertoireIndex;
