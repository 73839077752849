import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import {
  Row, Col, Typography, Space,
} from 'antd';

const { Text } = Typography;

const RepertoireListRow = function ({ data }) {
  const renderColumns = data.roles ? (
    <>
      <Col
        xs={24}
        lg={8}
      >
        <Text>{data.composer}</Text>
      </Col>
      <Col
        xs={24}
        lg={8}
      >
        <Space
          direction='vertical'
          size={0}
        >
          {map(data.roles, (role) => (
            <Text
              strong
              key={role.name}
            >
              {role.name}
              {role.fragments ? '*' : ''}
            </Text>
          ))}
        </Space>
      </Col>
      <Col
        xs={24}
        lg={8}
      >
        <Text>{data.title}</Text>
      </Col>
    </>
  ) : (
    <>
      <Col
        xs={24}
        lg={12}
      >
        <Text>{data.composer}</Text>
      </Col>
      <Col
        xs={24}
        lg={12}
      >
        <Text strong>{data.title}</Text>
      </Col>
    </>
  );

  return (
    <Row
      style={{ width: '100%' }}
      gutter={[
        {
          xs: 8,
          lg: 32,
        }, {
          xs: 8,
          lg: 32,
        },
      ]}
    >
      { renderColumns }
    </Row>
  );
};

RepertoireListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RepertoireListRow;
